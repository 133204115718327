import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverHeader,
  Portal,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import { numberFormat } from '../../../../libraries/utils';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { PopoverTrigger } from '../../dataTables/components/ClientTable';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { WarningIcon } from '@chakra-ui/icons';

const tableColumnsTopCreators = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Symbol',
    accessor: 'tickerSymbol',
  },
  {
    Header: 'Price',
    accessor: 'closePrice',
  },
  {
    Header: 'Other',
    accessor: '',
  },
  {
    Header: '',
    accessor: 'securityId',
  },
];

function AccountHolding(props) {
  const {
    securityData,
    isSecurityLoading,
    isDeleting,
    onRemoveHolding,
    isRemovable = false,
    setSelectedSecurityId,
    onOpen
  } = props;

  const columns = useMemo(() => tableColumnsTopCreators, [tableColumnsTopCreators]);
  const data = useMemo(() => securityData, [securityData]);
  const [isExpanded, setIsExpanded] = useState(true);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    prepareRow
  } =
    tableInstance;

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX="auto">
        <Flex
          align={{sm: 'flex-start', lg: 'center'}}
          justify="space-between"
          w="100%"
          px="22px"
          pb="20px"
          mt="10px"
          mb="10px"
          boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)">
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Portfolio Holdings ({securityData.length})
          </Text>
          <Flex
            align="center"
            gap={2}
          >
            <Button display={{
              base: 'block',
              lg: 'none'
            }}
                    variant="action"
                    onClick={() => setIsExpanded(prev => !prev)}
            >{isExpanded ? 'Collapse' : 'Expand'}</Button>
            <Button
              variant="no-hover"
              bg="transparent"
              p="0px"
              minW="unset"
              minH="unset"
              h="18px"
              w="max-content"
              disabled={!canPreviousPage}
              onClick={previousPage}
            >
              <Icon cursor="pointer" as={MdKeyboardArrowLeft} color={brandColor} w="24px"
                    h="24px" />
            </Button>
            <Button
              variant="no-hover"
              bg="transparent"
              p="0px"
              minW="unset"
              minH="unset"
              h="18px"
              w="max-content"
              disabled={!canNextPage}
              onClick={nextPage}
            >
              <Icon cursor="pointer" as={MdKeyboardArrowRight} color={brandColor} w="24px"
                    h="24px" />
            </Button>
          </Flex>
        </Flex>
        {
          isExpanded && <Table {...getTableProps()} variant="simple" color="gray.500">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor="transparent">
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{sm: '10px', lg: '12px'}}
                        color="gray.400">
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {
                isSecurityLoading ? <>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                      <Tr key={item}>
                        <Td colSpan={6} p={2}>
                          <Skeleton h="20px" />
                        </Td>
                      </Tr>
                    ))}
                  </> :
                  page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => {
                          let data = '';
                          if (cell.column.Header === 'Name') {
                            data = (
                              <Flex align="center">
                                <Text
                                  color={textColor}
                                  fontSize="md"
                                  fontWeight="600">
                                  {cell.value}
                                </Text>
                              </Flex>
                            );
                          } else if (cell.column.Header === 'Symbol') {
                            data = (
                              <Text
                                color={brandColor}
                                fontSize="md"
                                maxW={{base: 'auto', sm: '60px', lg: '80px'}}
                                fontWeight="500">
                                {cell.value}
                              </Text>
                            );
                          } else if (cell.column.Header === 'Price') {
                            data = (
                              <Text
                                color={textColorSecondary}
                                fontSize="md"
                                fontWeight="500">
                                ${numberFormat(cell.value)}
                              </Text>
                            );
                          } else if (cell.column.Header === 'Other') {
                            if (isRemovable) return;
                            data = (
                              <Button
                                colorScheme='brand'
                                fontSize="sm"
                                size='sm'
                                onClick={() => {
                                  setSelectedSecurityId(cell.row.original.securityId);
                                  onOpen();
                                }}>
                                Market Data
                              </Button>
                            );
                          } else if (cell.column.Header === '') {
                            if (!isRemovable) return;
                            data = (
                              <Popover placement="bottom-start">
                                {({onClose}) => (
                                  <>
                                    <PopoverTrigger>
                                      <IconButton
                                        bg="inherit"
                                        ms="auto"
                                        borderRadius="inherit"
                                        _hover="none"
                                        _active={{
                                          bg: 'inherit',
                                          transform: 'none',
                                          borderColor: 'transparent',
                                        }}
                                        _focus={{
                                          boxShadow: 'none',
                                        }}
                                        disabled={isDeleting}
                                        icon={
                                          <IoMdRemoveCircleOutline
                                            color="secondaryGray.500" w="20px"
                                            h="20px" />
                                        }></IconButton>
                                    </PopoverTrigger>
                                    <Portal>
                                      <PopoverContent width="270px">
                                        <PopoverHeader>
                                          <Box display="flex" marginBottom="8px">
                                            <WarningIcon color="orange"
                                                         marginRight="4px"
                                                         marginTop="4px" />
                                            <Box>{`Are you sure to remove "${cell.row.original.tickerSymbol}" security?`}</Box>
                                          </Box>
                                          <Box display="flex"
                                               justifyContent="flex-end">
                                            <Button size="xs" margin="4px"
                                                    width="50px"
                                                    onClick={onClose}>No</Button>
                                            <Button size="xs" margin="4px"
                                                    width="50px"
                                                    colorScheme="red"
                                                    onClick={() => {
                                                      onRemoveHolding(cell.value, cell.row.original.tickerSymbol);
                                                      onClose();
                                                    }}>Yes</Button>
                                          </Box>
                                        </PopoverHeader>
                                      </PopoverContent>
                                    </Portal>
                                  </>
                                )}
                              </Popover>
                            )
                          }
                          return (
                            <Td
                              {...cell.getCellProps()}
                              key={index}
                              fontSize={{sm: '14px'}}
                              py="8px"
                              minW={{sm: '150px', md: '200px', lg: 'auto'}}
                              borderColor="transparent">
                              {data}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
            </Tbody>
          </Table>
        }
      </Flex>
    </>
  );
}

export default AccountHolding;
